<template>
  <vh-dialog
    title="添加聊天机器人"
    :visible.sync="visibleTemp"
    :close-on-click-modal="false"
    :append-to-body="true"
    :modal-append-to-body="true"
    width="468px"
    custom-class="bot-import-dialog"
    @close="cancelImport"
  >
    <div class="upload-dialog-content">
      <file-upload
        ref="viewerUpload"
        v-model.trim="fileUrl"
        @delete="deleteFile"
        :saveData="{
          path: pathUrl,
          type: 'exel'
        }"
        :on-success="uploadSuccess"
        :on-progress="uploadProcess"
        :on-error="uploadError"
        :on-preview="uploadPreview"
        :before-upload="beforeUploadHandler"
        :disabled="isUploadDisabled"
      >
        <div slot="upload-result">
          <!-- 状态1： 有上传过文件，后面重新删除等-变为未上传 -->
          <p slot="tip" v-if="uploadResult && uploadResult.status === 'start' && fileUrl">
            请使用模版上传文件
          </p>
          <!-- 状态2： 已选择文件，上传中 或者 检测中-->
          <div v-if="uploadResult && uploadResult.status === 'progress'">
            <div class="progress-check-box">
              <div>
                <svg viewBox="25 25 50 50" class="circular">
                  <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
                </svg>
              </div>
              <div>数据检测上传中...</div>
            </div>
            <!-- <div class="progressBox">
                <vh-progress :percentage="percent"></vh-progress>
              </div> -->
          </div>
          <!-- 状态3： 检测失败 -->
          <div class="change-txt" v-if="uploadResult && uploadResult.status === 'error'">
            <p class="p-error">{{ uploadResult.text }}</p>
          </div>
          <!-- 状态4:  检测成功 -->
          <div class="change-txt" v-if="uploadResult && uploadResult.status === 'success'">
            <p class="p-right">
              上传成功，共检测到
              <span class="blue">{{ importResult && importResult.success }}</span>
              条有效数据，
              <span class="red">{{ importResult && importResult.fail }}</span>
              条无效数据
            </p>
          </div>
        </div>
        <!-- 状态5： 未上传 -->
        <p slot="tip" v-if="uploadResult && uploadResult.status === 'start' && !fileUrl">
          请使用模版上传文件
        </p>
        <!-- 状态6：上传失败，后端有报错 -->
        <p
          slot="tip"
          class="p-error"
          v-if="uploadResult && uploadResult.status === 'error' && !fileUrl"
        >
          {{ uploadResult.text }}
        </p>
      </file-upload>
      <p class="uploadtips" v-show="importResult && importResult.fail > 0">
        <span class="down-span-text" @click.prevent.stop="downErrorHandle">下载查看无效数据</span>
      </p>
      <p class="uploadtips">
        <span>提示：单个文件不超过500条数据，每个主题最多添加500个聊天机器人</span>
      </p>
    </div>
    <div slot="footer">
      <vh-button
        v-preventReClick
        type="info"
        @click.prevent.stop="cancelImport"
        size="medium"
        round
        plain
      >
        取消
      </vh-button>
      <vh-button
        type="primary"
        v-preventReClick
        @click.prevent.stop="saveUserList"
        class="loading-btn"
        size="medium"
        round
        :disabled="fileResult === 'error' || !isUploadEnd || importResult.success == 0"
      >
        确定
      </vh-button>
    </div>
  </vh-dialog>
</template>
<script>
  import StaticFileUrlsMap from '@/api/StaticFileUrl.js';
  import FileUpload from '@/components/FileUpload/main';

  export default {
    name: 'CompImportDialog',
    components: {
      FileUpload
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      currentTotal: {
        type: [Number, String],
        default: 0
      }
    },
    data() {
      return {
        visibleTemp: false,
        vm: null,
        isUploadEnd: false,
        uploadResult: {
          status: 'start',
          text: '请选择模板文件'
        },
        percent: 0,
        downloadUrl: StaticFileUrlsMap.getBotDownTemplateUrl(), // 下载模板地址
        fileUrl: '', // 文件地址
        fileResult: '', // 文件上传结果
        importResult: null
      };
    },
    watch: {},
    computed: {
      pathUrl: function () {
        return `webinars/form-user-docs`;
      },
      isUploadDisabled: function () {
        return this.uploadResult.status === 'progress';
      }
    },
    methods: {
      // 下载模板
      downloadTemplate() {
        const xHttp = new window.XMLHttpRequest();
        xHttp.open('GET', this.downloadUrl, true);
        xHttp.responseType = 'blob';
        xHttp.onload = () => {
          const url = window.URL.createObjectURL(xHttp.response);
          const aDom = document.createElement('a');
          aDom.href = url;
          aDom.download = '设置AI聊天机器人.xlsx';
          aDom.click();
        };
        xHttp.send();
      },
      // 关闭导入
      cancelImport() {
        this.isUploadEnd = false;
        this.fileUrl = '';
        this.uploadResult = {
          status: 'start',
          text: '请上传文件'
        };
        this.$emit('close', 'close');
      },
      // 删除选中的文件
      deleteFile() {
        this.fileUrl = '';
        this.isUploadEnd = false;
        this.uploadResult = {
          status: 'start',
          text: '请上传文件'
        };
      },
      // 上传前检测
      beforeUploadHandler(file) {
        console.log(file);
        const typeList = ['xls', 'xlsx'];
        let nameArr = file.name.split('.');
        const isType = typeList.includes(nameArr[nameArr.length - 1]); // typeList.includes(file.type.toLowerCase());
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isType) {
          this.$vhMessage({
            message: `上传格式只能是 ${typeList.join('、')} 格式!`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage({
            message: `上传文件大小不能超过 2M!`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        return isType && isLt2M;
      },
      // 文件上传成功 & 文档预检
      uploadSuccess(res, file) {
        console.log(res, file);
        if (res.data.file_url) {
          this.fileUrl = res.data.domain_url;
          // 文件上传成功，检测观众
          this.$fetch('checkImportBot', {
            import_url: this.fileUrl,
            current_total: this.currentTotal
          })
            .then(resV => {
              if (resV && resV.code == 200 && resV.data) {
                this.isUploadEnd = true;
                this.fileResult = 'success';
                this.uploadResult = {
                  status: 'success',
                  text: '检测成功'
                };
                this.importResult = {
                  success: resV.data.success_count,
                  fail: resV.data.failure_count,
                  botList: resV.data.success_list
                };
                if (this.$refs.viewerUpload) {
                  this.$refs.viewerUpload.setError('');
                }
              } else {
                this.renderCheckImportError(resV.msg, 'import', true);
              }
            })
            .catch(resV => {
              this.renderCheckImportError(resV.msg, 'import', true);
            });
        } else {
          this.renderCheckImportError(res.msg, 'import', false);
        }
      },
      /* 报名导入预检-失败处理（代码冗余缩短） */
      renderCheckImportError(msg, type, isSetError) {
        this.isUploadEnd = true;
        this.fileResult = 'error';
        this.uploadResult = {
          status: 'error',
          text: msg
        };
        if (isSetError) {
          this.importResult = null;
          if (this.$refs.viewerUpload) {
            this.$refs.viewerUpload.setError(msg || `检测失败，请重新上传`);
          }
        }
      },
      // 文件上传中
      uploadProcess(event, file, fileList) {
        console.log('uploadProcess', event, file, fileList);
        this.isUploadEnd = false;
        this.uploadResult = {
          status: 'progress',
          text: '上传中，请稍候'
        };
        this.percent = parseInt(event.percent);
      },
      // 文件上传失败
      uploadError(err, file, fileList) {
        console.log('uploadError', err, file, fileList);
        // this.$vhMessage.error(`文件上传失败`);
        this.uploadResult = {
          status: 'error',
          text: err.msg || '文件上传失败'
        };
        this.fileResult = 'error';
      },
      // 文件预览
      uploadPreview(file) {
        console.log('uploadPreview', file);
      },
      // 下载无效数据
      downErrorHandle() {
        this.$fetch('downloadBotFailData', {
          import_url: this.fileUrl,
          webinar_id: this.$route.query.webinar_id
        }).then(res => {
          if (res && res.code === 200) {
            this.$vhMessage({
              message: `无效数据导出申请成功，${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
          } else {
            this.$vhMessage.error(res.msg);
          }
        });

        // this.$EventBus.$emit('saas_vs_download_change');
      },
      /* 报名导入-保存 */
      saveUserList() {
        if (!this.fileUrl) {
          this.$vhMessage({
            message: `请先选择模板`,
            showClose: true,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        this.$emit('batchAddBot', this.importResult.botList);
      }
    },
    created() {
      // 外层控制内层dialog是否开启
      this.visibleTemp = this.visible;
      if (this.visibleTemp) {
        this.fileUrl = null;
        this.fileResult = '';
        this.importResult = null;
      }
    },
    mounted() {},
    beforeDestroy() {}
  };
</script>
<style lang="less">
  /* 导入报名用户样式-------------------------------------- */
  .bot-import-dialog .upload-dialog-content {
    overflow: hidden;
    /* 文件上传 */
    .p-right {
      font-weight: 400;
      margin-top: -5px;
      color: #888;
      font-size: 14px;
    }
    .p-error {
      font-weight: 400;
      margin-top: -5px;
      color: #fb3a32;
      font-size: 14px;
    }

    .progress-check-box {
      div {
        display: inline-block;
        vertical-align: middle;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        &:first-child {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }
      .circular {
        height: 20px;
        width: 20px;
        animation: loading-rotate 2s linear infinite;
        .path {
          animation: loading-dash 1.5s ease-in-out infinite;
          stroke-dasharray: 90, 150;
          stroke-dashoffset: 0;
          stroke-width: 2;
          stroke: #fb3a32;
          stroke-linecap: round;
        }
      }
    }
    .progressBox {
      ::v-deep .vh-progress-bar__inner {
        background-color: #14ba6a;
      }
    }
    .uploadtips {
      padding-top: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #999999;
      overflow: hidden;
      .down-span-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3562fa;
        float: right;
        cursor: pointer;
      }
    }

    .dialog-right-btn {
      text-align: right;
      margin-top: 18px;
      .vh-button--medium.hasIcon {
        display: inline-block;
      }
    }

    a.down-a-btn {
      float: left;
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 36px;
      color: #3562fa;
      cursor: pointer;
    }
    .red {
      color: #fb2626;
    }
    .blue {
      color: #3562fa;
    }
  }
</style>
