var StaticBaseUrl = {
  test: 'https://t-alistatic01.e.vhall.com/upload/',
  base: 'https://cnstatic01.e.vhall.com/upload/'
};
var SignUploadTemplateUrlNew = [
  'https://cnstatic01.e.vhall.com/upload/register-form/tpl/202311/40/81/4081e801834ba82e9402525d0ea1deeb8870.xlsx', // 报名表单-下载上传模板- 直播
  'https://cnstatic01.e.vhall.com/upload/register-form/tpl/202311/40/81/4081e801834ba82e9402525d0ea1deeb8870.xlsx' // 报名表单-下载上传模板- 专题
];
var SmsNoticeUploadTemplateUrlNew = [
  'https://t-alistatic01.e.vhall.com/upload/webinars/notice-user-docs/e4/7e/e47e7ed8e960ad29cf0cc86e485971ce.xlsx', // 消息通知-下载上传模板-静态地址（历史） --- 测试
  'https://cnstatic01.e.vhall.com/upload/webinars/notice-user-docs/e4/7e/e47e7ed8e960ad29cf0cc86e485971ce.xlsx' // 消息通知-下载上传模板-静态地址（历史） --- 线上
];
var BotUploadTemplateUrl = [
  'https://cnstatic01.e.vhall.com/common-static/file-template/%E8%AE%BE%E7%BD%AE%E8%81%8A%E5%A4%A9%E6%9C%BA%E5%99%A8%E4%BA%BA.xlsx' // 机器人-下载上传模板
];
var StaticFileUrlsMap = {
  /**
   * getSignDownUrl 获取机器人下载模板路径
   * @returns
   */
  getBotDownTemplateUrl() {
    return BotUploadTemplateUrl[0];
  },
  /**
   * getSignDownUrl 获取报名表单下载模板路径
   * @param {*} isSubject 是否是专题  默认 1
   * @returns
   */
  getSignDownTemplateUrl(isSubject = 1) {
    return SignUploadTemplateUrlNew[isSubject ? 1 : 0];
  },
  /**
   * getSmsNoticeDownTemplateUrl 获取消息通知下载模板路径
   * @param {*} processKey 传递当前环境标记，当前指的是 process.env.NODE_ENV
   * @returns
   */
  getSmsNoticeDownTemplateUrl(processKey) {
    return ['production', 'pre'].includes(processKey)
      ? SmsNoticeUploadTemplateUrlNew[1]
      : SmsNoticeUploadTemplateUrlNew[0];
  },
  /**
   * getDownBaseUrl 获取消息通知下载模板路径
   * @param {*} processKey 传递当前环境标记，当前指的是 process.env.NODE_ENV
   * @returns
   */
  getDownBaseUrl(processKey) {
    return ['production', 'pre'].includes(processKey) ? StaticBaseUrl.base : StaticBaseUrl.test;
  }
};
export default StaticFileUrlsMap;
