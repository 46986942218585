<template>
  <div class="createTopic">
    <page-title :pageTitle="$route.meta.title"></page-title>
    <div class="mainContent">
      <vh-form :model="formData" ref="ruleForm" :rules="rules" label-width="100px">
        <vh-form-item label="内容主题" prop="name" class="topicName">
          <vh-input
            v-model="formData.name"
            autocomplete="off"
            type="text"
            :maxlength="100"
            :show-word-limit="true"
            placeholder="必填项，请输入内容主题以便于区分使用场景"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="开启方式" prop="openWay" class="topicOpenWay">
          <vh-radio-group v-model="formData.openWay">
            <vh-radio :label="0">手动开启</vh-radio>
            <vh-radio :label="1">定时开启</vh-radio>
          </vh-radio-group>
          <div class="tip" v-show="formData.openWay != 1">
            直播过程中，需要助理在直播间手动开启使用
          </div>
        </vh-form-item>
        <vh-form-item
          label="时间设置"
          prop="timeSet"
          class="topicTimeSet"
          v-if="formData.openWay == 1"
        >
          <vh-radio-group v-model="formData.timeSet">
            <vh-radio :label="0">跟随直播状态</vh-radio>
            <vh-radio :label="1">自定义时间</vh-radio>
          </vh-radio-group>
          <div class="tip" v-show="formData.timeSet != 1">
            直播后自动开启，当活动结束或状态变更后自动关闭，也可以人工提前关闭
          </div>
          <div class="diy" v-show="formData.timeSet == 1">
            开播后
            <vh-input v-model="formData.beginTime">
              <template slot="append">分钟</template>
            </vh-input>
            ，发送
            <vh-input v-model="formData.endTime">
              <template slot="append">分钟</template>
            </vh-input>
            后停止发送
          </div>
        </vh-form-item>
        <vh-form-item label="聊天机器人" prop="bot" class="topicBot">
          <div class="selectedBot" v-if="selectedBot != 0" @click="setBot">
            <div class="box">已设置{{ selectedBot }}个聊天机器人</div>
            <span class="iconfont-v3 saasicon-reset1"></span>
          </div>
          <div class="selectBot" v-else @click="setBot">
            <span class="iconfont-v3 saasline-plus"></span>
            设置聊天机器人
          </div>
        </vh-form-item>
        <vh-form-item label="发送方式" prop="sendWay" class="topicSendWay">
          <vh-radio-group v-model="formData.sendWay">
            <vh-radio :label="0">随机发言</vh-radio>
            <vh-radio :label="1">顺序发言</vh-radio>
          </vh-radio-group>
          <div class="tip">
            {{
              formData.sendWay != 1
                ? '每次聊天会随机在聊天机器人中选择并发送一条设置的内容，全部发送完成后不再发送'
                : '每次聊天会按照设置的马甲顺序发送内容，全部发送完成后不再发送'
            }}
          </div>
        </vh-form-item>
        <vh-form-item label="聊天间隔" prop="chatDuring" class="topicChatDuring">
          <vh-input v-model="formData.chatDuring" placeholder="1-300" :maxlength="3">
            <template slot="append">秒</template>
          </vh-input>
          <div class="tip">
            每条聊天内容（不包含真实观众及特殊角色）发送的间隔时间，并发大时可能会有延迟
          </div>
        </vh-form-item>
        <vh-form-item label="礼物间隔" prop="giftDuring" class="topicGiftDuring">
          <vh-radio-group v-model="formData.giftDuring">
            <vh-radio :label="0">不发送礼物</vh-radio>
            <vh-radio :label="1">
              发送礼物
              <vh-input v-model="formData.giftDuringNum" :maxlength="3" placeholder="1-300">
                <template slot="append">秒</template>
              </vh-input>
            </vh-radio>
          </vh-radio-group>
          <div class="tip">按照礼物列表的展示顺序进行发送，并发大时可能会有延迟</div>
        </vh-form-item>
      </vh-form>
      <vh-button type="primary" class="save" round @click="saveTopic" v-preventReClick>
        保存
      </vh-button>
      <BotSet ref="botRef" :topic_id="topic_id" @updateBots="updateBots"></BotSet>
    </div>
    <!-- 同步弹出框 -->
    <vh-dialog
      title="提示"
      :visible.sync="asyncDialog.visible"
      :show-close="false"
      class="zdy-async-dialog"
      width="400px"
    >
      <div class="async__body">
        <div class="async__ctx">
          <p>{{ asyncDialog.question }}</p>
          <vh-checkbox v-model="asyncDialog.sureChecked">{{ asyncDialog.show }}</vh-checkbox>
        </div>
        <div class="async__footer">
          <vh-button type="info" plain size="medium" @click="unSureAsyncHandle" round>
            取消
          </vh-button>
          <vh-button type="primary" size="medium" v-preventReClick @click="sureAsyncHandle" round>
            确定
          </vh-button>
        </div>
      </div>
    </vh-dialog>

    <!-- 开播按钮 -->
    <begin-play
      :webinarId="isInWebinar"
      v-if="$route.query.type != 5 && webinarState != 4 && isInWebinar"
    ></begin-play>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import BotSet from './botSet';
  import { isEmbed, sessionOrLocal } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';

  export default {
    name: 'ChatEdit',
    components: {
      PageTitle,
      BotSet,
      beginPlay
    },
    data() {
      const validBot = (rule, value, callback) => {
        if (this.selectedBot == 0) {
          callback(new Error('请设置聊天机器人'));
        } else {
          callback();
        }
      };
      const validTimeSet = (rule, value, callback) => {
        if (value == 1) {
          //自定义时间
          if (!this.formData.beginTime || !this.formData.endTime) {
            callback(new Error('请设置自定义时间'));
          } else if (
            parseInt(this.formData.beginTime + '') > 1000 ||
            parseInt(this.formData.endTime + '') > 1000
          ) {
            callback(new Error('自定义时间不能超过1000分钟'));
          } else {
            callback();
          }
        } else {
          callback();
        }
      };
      const validGiftDuring = (rule, value, callback) => {
        if (value == 1) {
          if (!this.formData.giftDuringNum) {
            callback(new Error('请设置礼物间隔'));
          } else if (parseInt(this.formData.giftDuringNum + '') > 300) {
            callback(new Error('礼物间隔不能超过300秒'));
          } else {
            callback();
          }
        } else {
          callback();
        }
      };
      const validChatDuring = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入聊天间隔'));
        } else if (parseInt(value + '') > 300) {
          callback(new Error('聊天间隔不能超过300秒'));
        } else {
          callback();
        }
      };
      return {
        isEmbed: isEmbed(),
        formData: {
          name: '',
          openWay: 0,
          timeSet: 0,
          beginTime: '5',
          endTime: '5',
          sendWay: 0,
          chatDuring: '5',
          giftDuring: 0,
          giftDuringNum: '10',
          chatbot_number: 0,
          chatbot_list: []
        },
        selectedBot: 0, //已设置的机器人总数
        rules: {
          name: [{ required: true, message: '请输入内容主题', trigger: 'blur' }],
          openWay: [{ required: true, trigger: 'blur' }],
          timeSet: [{ required: true, validator: validTimeSet, trigger: 'change' }],
          bot: [{ required: true, validator: validBot, trigger: 'change' }],
          sendWay: [{ required: true, trigger: 'blur' }],
          chatDuring: [{ required: true, validator: validChatDuring, trigger: 'change' }],
          giftDuring: [{ required: true, validator: validGiftDuring, trigger: 'change' }]
        },
        asyncDialog: {
          visible: false,
          question: '保存聊天机器人同时共享至账号下的AI聊天中，便于其他活动使用？',
          show: '共享到AI聊天',
          sureChecked: true
        },
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')) // 活动状态
      };
    },
    watch: {
      'formData.beginTime'(val) {
        this.formData.beginTime = (val + '').match(/^([1-9][0-9]*)$/)
          ? (val + '').match(/^([1-9][0-9]*)$/)[0]
          : '';
      },
      'formData.endTime'(val) {
        this.formData.endTime = (val + '').match(/^([1-9][0-9]*)$/)
          ? (val + '').match(/^([1-9][0-9]*)$/)[0]
          : '';
      },
      'formData.chatDuring'(val) {
        this.formData.chatDuring = (val + '').match(/^([1-9][0-9]*)$/)
          ? (val + '').match(/^([1-9][0-9]*)$/)[0]
          : '';
      },
      'formData.giftDuringNum'(val) {
        this.formData.giftDuringNum = (val + '').match(/^([1-9][0-9]*)$/)
          ? (val + '').match(/^([1-9][0-9]*)$/)[0]
          : '';
      }
    },
    computed: {
      isInWebinar() {
        return this.$route.query.webinar_id;
      },
      topic_id() {
        return this.$route.params.id;
      },
      source() {
        let path = this.$route.path;
        if (path.indexOf('edit') != -1) {
          //编辑
          return 'edit';
        } else {
          //新增
          return 'add';
        }
      }
    },
    created() {
      if (this.source == 'edit') {
        this.getTopicInfo();
      } else {
        this.getBotList();
      }
    },
    mounted() {},
    methods: {
      saveTopic() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            console.log(this.formData);
            if (this.isInWebinar && this.source == 'add' && !this.isEmbed) {
              this.asyncDialog.visible = true;
              this.asyncDialog.sureChecked = true;
            } else {
              this.sureAsyncHandle();
            }
          }
        });
      },
      unSureAsyncHandle() {
        this.asyncDialog.visible = false;
      },
      sureAsyncHandle() {
        this.asyncDialog.visible = false;

        let params = {
          content_title: this.formData.name,
          activation_mode: this.formData.openWay,
          timed_start_setting: JSON.stringify({
            type: this.formData.timeSet, // 类型，0-跟随直播状态 1-自定义时间
            start_delay_minutes: this.formData.beginTime, // 开播延迟启动分钟数
            duration_minutes: this.formData.endTime // 持续分钟数
          }),
          chatbot_list: JSON.stringify(this.formData.chatbot_list),
          sending_mode: this.formData.sendWay,
          chat_interval: this.formData.chatDuring,
          gift_interval: this.formData.giftDuring == 1 ? this.formData.giftDuringNum : -1
        };

        if (this.isInWebinar) {
          params.webinar_id = this.isInWebinar;
          params.share_to_library = this.asyncDialog.sureChecked ? 1 : 0;
        }
        if (this.source == 'edit') {
          params.topic_id = this.topic_id;
        }

        this.$fetch('saveTopic', params)
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage.success('保存成功');
              let path = (this.isEmbed ? '/embed' : '') + '/AI/chat/list';
              if (this.isInWebinar) {
                path = (this.isEmbed ? '/embed' : '') + '/live/AI/chat/list/' + this.isInWebinar;
              }
              this.$router.push(path);
            } else {
              this.$vhMessage({
                message: res.msg || `保存失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `保存失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      async getTopicInfo() {
        let res = await this.$fetch('getTopicInfo', {
          topic_id: this.topic_id
        });
        if (res.code == 200 && res.data) {
          let info = res.data;
          this.formData.name = info.content_title;
          this.formData.openWay = parseInt(info.activation_mode + '');
          this.formData.timeSet = parseInt(info.timed_start_setting.type + '');
          this.formData.beginTime = info.timed_start_setting.start_delay_minutes;
          this.formData.endTime = info.timed_start_setting.duration_minutes;
          this.formData.sendWay = parseInt(info.sending_mode + '');
          if (info.chatbot_number != 0) {
            this.selectedBot = info.chatbot_number;
            this.formData.chatbot_number = info.chatbot_number;
            this.formData.chatbot_list = info.chatbot_list;
          }

          this.formData.chatDuring = info.chat_interval;
          this.formData.giftDuring = info.gift_interval == -1 ? 0 : 1;
          if (this.formData.giftDuring == 1) {
            this.formData.giftDuringNum = info.gift_interval;
          }
          this.refreshList();
        }
        this.$refs.botRef.initTb(this.formData.chatbot_list);
      },
      async getBotList() {
        let res = await this.$fetch('getBotList', {
          topic_id: this.topic_id
        });
        if (res.code == 200 && res.data) {
          let info = res.data;
          this.formData.chatbot_list = info.list;
          this.formData.chatbot_number = info.total;
          this.refreshList();
        }
        this.$refs.botRef.initTb(this.formData.chatbot_list);
      },
      refreshList() {
        for (let index = 0; index < this.formData.chatbot_list.length; index++) {
          if (!this.formData.chatbot_list[index].botId) {
            this.formData.chatbot_list[index].botId =
              'botId' + new Date().getTime() + Math.floor(Math.random() * 1000 + 1);
          }
          this.formData.chatbot_list[index].show = true;
        }
      },
      updateBots(info) {
        // console.log(info);
        this.selectedBot = info.length;
        this.formData.chatbot_list = JSON.parse(JSON.stringify(info));
        this.formData.chatbot_number = info.length;
      },
      setBot() {
        if (this.selectedBot == 0) {
          this.$refs.botRef.open(null);
        } else {
          this.refreshList();
          this.$refs.botRef.open(this.formData.chatbot_list);
        }
      }
    }
  };
</script>

<style lang="less">
  .createTopic {
    .mainContent {
      background-color: #fff;
      padding: 48px;
      .vh-form {
        .vh-input {
          width: 104px;
          .vh-input-group__append {
            padding: 0 10px;
          }
        }
        .vh-input--limit .vh-input__inner,
        .vh-input--limit.vh-input--medium .vh-input__inner,
        .vh-input--limit.vh-input--mini .vh-input__inner,
        .vh-input--limit.vh-input--small .vh-input__inner {
          padding-right: 54px;
        }
        .topicName .vh-input {
          width: 640px;
        }
        .vh-textarea {
          width: 628px;
        }
        .vh-radio-group {
          &.topic_method {
            .vh-radio {
              display: flex;
              align-items: center;
              &:first-child {
                margin-bottom: 10px;
              }
              .vh-radio__label {
                display: flex;
                align-items: center;
                .vh-input {
                  width: 150px;
                  margin: 0 8px;
                }
              }
            }
          }
        }
        input[type='number'] {
          line-height: 1px !important;
        }
        .topicGiftDuring .vh-radio {
          line-height: 40px;
        }
        .topicOpenWay {
          .vh-form-item__content {
            line-height: 36px;
          }
        }
        .topicBot {
          .selectedBot {
            display: flex;
            width: 210px;
            .box {
              width: 180px;
              height: 40px;
              border-radius: 40px;
              text-align: center;
              font-size: 14px;
              border: 1px solid #d9d9d9;
              background-color: #f5f5f5;
              cursor: pointer;
            }
            span {
              transform: rotateY(180deg);
              display: inline-block;
              margin-left: 8px;
              cursor: pointer;
              color: #3562fa;
            }
          }
          .selectBot {
            width: 153px;
            height: 40px;
            border-radius: 40px;
            text-align: center;
            font-size: 14px;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            span {
              font-size: 12px;
            }
          }
        }
        .topicChatDuring {
          .tip {
            margin-top: 4px;
          }
        }
      }
      .tip {
        color: #8c8c8c;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
      .save {
        margin-left: 100px;
        width: 152px;
      }
    }
    .zdy-async-dialog .async__footer {
      padding-bottom: 0;
    }
  }
</style>
