<template>
  <!-- 弹框 -->
  <vh-dialog
    title="设置聊天机器人"
    :visible.sync="dialogBotSet"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    class="botSetDialog"
    width="750px"
  >
    <div slot="title">
      <span class="title">设置聊天机器人</span>
      <span class="total">
        <span class="highlight">{{ total }}</span>
        <span>/500</span>
      </span>
    </div>
    <div class="tools">
      <vh-button size="medium" plain type="primary" class="btn-add" round @click="addBot">
        添加机器人
      </vh-button>
      <vh-button size="medium" class="btn-adds" round plain @click="batchAddBot">
        批量添加
      </vh-button>
      <vh-button
        size="medium"
        class="btn-del"
        round
        plain
        @click="allDelete"
        :disabled="!bot_ids.length"
      >
        批量删除
      </vh-button>
      <a href="javascript:void(0);" class="downloadBtn" @click="downloadTemplate">下载模板</a>
      <vh-input
        round
        class="search"
        size="medium"
        placeholder="请输入昵称或内容"
        v-model="keyword"
        clearable
        @clear="initQueryBotList"
        @keyup.enter.native="initQueryBotList"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="initQueryBotList"></i>
      </vh-input>
    </div>
    <vh-table
      :data="tableDataFilter"
      row-key="botId"
      tooltip-effect="dark"
      style="width: 100%; min-height: 400px"
      max-height="400"
      @selection-change="handleTableCheckbox"
      :header-cell-style="{ background: '#f7f7f7', color: '#595959', height: '56px' }"
    >
      <vh-table-column type="selection" width="55" align="left" />
      <vh-table-column label="头像" width="80">
        <div class="" slot="header">
          <span>头像</span>
          <vh-tooltip
            class="item"
            effect="dark"
            placement="top-start"
            :popper-options="{ zIndex: 4000 }"
          >
            <div slot="content">头像建议尺寸：140*140px，小于2M，支持jpg、gif、png、bmp</div>
            <span class="iconfont-v3 saasicon_help_m" style="margin-left: 4px"></span>
          </vh-tooltip>
        </div>
        <template slot-scope="scope">
          <div class="flexBox">
            <div class="gift-cover">
              <upload
                class="upload__avatar"
                :ref="`webinar_uploader${scope.row.botId}`"
                :widthImg="40"
                :heightImg="40"
                :domain_url="scope.row.avatar"
                :saveData="saveImageData"
                :on-success="
                  (res, file) => {
                    handleUploadSuccess(res, file, scope.row);
                  }
                "
                :on-error="uploadError"
                :on-change="
                  (file, fileList) => {
                    handleUploadChange(file, fileList, scope.row);
                  }
                "
                :auto-upload="false"
                :isShowDel="false"
              ></upload>
            </div>
          </div>
        </template>
      </vh-table-column>
      <vh-table-column label="昵称" width="150">
        <template slot-scope="scope">
          <vh-input v-model="scope.row.nickname" :show-word-limit="true" :maxlength="30"></vh-input>
        </template>
      </vh-table-column>
      <vh-table-column label="发言内容">
        <template slot-scope="scope">
          <vh-input
            v-model="scope.row.chat_content"
            :show-word-limit="true"
            :maxlength="300"
          ></vh-input>
        </template>
      </vh-table-column>
      <vh-table-column label="操作" width="80">
        <template slot-scope="scope">
          <span class="del" @click="del(scope.row)">删除</span>
        </template>
      </vh-table-column>
      <div class="" slot="empty">
        <null-page :nullType="nullType" class="search-no-data" :height="60"></null-page>
      </div>
    </vh-table>
    <div slot="footer">
      <!-- <div class="total">
        <span class="highlight">{{ total }}</span>
        <span>/500</span>
      </div> -->
      <vh-button type="info" plain size="medium" @click="closeBot" round>取消</vh-button>
      <vh-button
        type="primary"
        size="medium"
        :disabled="tableData.length == 0"
        v-preventReClick
        @click="saveBot"
        round
      >
        确定
      </vh-button>
    </div>
    <!-- 裁剪图片弹窗 -->
    <cropper
      ref="webinarCropper"
      :ratio="1"
      :appendToBody="true"
      :modalAppendToBody="true"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
    ></cropper>

    <!-- 导入报名用户excel -->
    <ImportBot
      v-if="importVisible"
      :visible="importVisible"
      :currentTotal="total"
      @close="cancelImport"
      @batchAddBot="batchAddBotSuccess"
    ></ImportBot>
  </vh-dialog>
</template>

<script>
  import upload from '@/components/Upload/main';
  import cropper from '@/components/Cropper/index';
  import ImportBot from './importBot';
  import { buildUrlByMode, parseQueryString, buildOssUploadProcessData } from '@/utils/utils';
  import NullPage from '../../PlatformModule/Error/nullPage';
  import StaticFileUrlsMap from '@/api/StaticFileUrl.js';
  import { defaultAvatar } from '@/utils/ossImgConfig';
  export default {
    name: 'BotSetDialog',
    props: {
      couponId: {
        type: [String, Number],
        default: ''
      }
    },
    data() {
      return {
        tableData: [], // 列表数据
        tableDataOrigin: [], // 列表原始数据
        dialogBotSet: false,
        bot_ids: [],
        upload_process_data: '', //图片上传时的裁切数据
        currentMode: 1,
        keyword: '',
        currentId: '',
        importVisible: false,
        downloadUrl: StaticFileUrlsMap.getBotDownTemplateUrl() // 下载模板地址
      };
    },
    props: {
      topic_id: {
        type: String,
        default: ''
      }
    },
    components: {
      upload,
      cropper,
      ImportBot,
      NullPage
    },
    computed: {
      nullType() {
        return this.total == 0 ? 'null' : 'search';
      },
      total() {
        return this.tableData.length || 0;
      },
      pathUrl: function () {
        return `interacts/screen-imgs/${dayjs().format('YYYYMM')}`;
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: this.pathUrl,
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      },
      tableDataFilter() {
        return this.tableData.filter(e => {
          return e.show == true;
        });
      }
    },
    methods: {
      // 下载模板
      downloadTemplate() {
        const xHttp = new window.XMLHttpRequest();
        xHttp.open('GET', this.downloadUrl, true);
        xHttp.responseType = 'blob';
        xHttp.onload = () => {
          const url = window.URL.createObjectURL(xHttp.response);
          const aDom = document.createElement('a');
          aDom.href = url;
          aDom.download = '设置AI聊天机器人.xlsx';
          aDom.click();
        };
        xHttp.send();
      },
      del(row) {
        for (let index = 0; index < this.tableData.length; index++) {
          if (this.tableData[index].botId == row.botId) {
            this.tableData.splice(index, 1);
            break;
          }
        }
      },
      // 复选框操作
      handleTableCheckbox(val) {
        this.bot_ids = val.map(item => item.botId);
      },
      resetChooseFile() {
        this.$refs['webinar_uploader' + this.currentId].resetChooseFile();
      },
      /**
       * 图片裁切完成后的回调函数
       * @param {any} cropperData
       * @param {any} url
       * @param {any} mode
       * @returns {any}
       */
      cropComplete(cropperData, url, mode, index, blurryDegree, lightDegree) {
        this.currentMode = mode;

        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          // 这段代码将在下一个DOM更新循环后执行
          this.$refs['webinar_uploader' + this.currentId].upload();
        });
      },
      /**
       * 图片本地裁剪预览
       * @param {*} file
       * @param {*} fileList
       */
      handleUploadChange(file, fileList, row) {
        console.log('handleUploadChange----->', file, fileList);
        if (file.status == 'ready') {
          this.currentId = row.botId;
          this.$checkUploadType(file.raw, this, 1, 4, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.webinarCropper.showModel(e.target.result);
            };
          });
        }
      },
      uploadError(err, file, fileList) {
        console.log('uploadError', err, file, fileList);
        this.$vhMessage({
          message: '头像上传失败',
          showClose: true,
          // duration: 0,
          type: 'error',
          customClass: 'zdy-info-box'
        });
      },
      /**
       * 文件上传成功，保存信息
       *
       * @param   {[type]}  res   [服务端结果响应]
       * @param   {[type]}  file  [上传成功的文件对象]
       *
       * @return  {[type]}        [return void]
       */
      handleUploadSuccess(res, file, row) {
        console.log('handleUploadSuccess------>', res, file, row);
        if (res.data) {
          for (let index = 0; index < this.tableData.length; index++) {
            if (this.tableData[index].botId == row.botId) {
              this.tableData[index].avatar = this.getDomainUrl(res.data.domain_url);
              break;
            }
          }
        }
      },
      getDomainUrl(imageUrl) {
        if (!imageUrl) return '';
        let obj = parseQueryString(imageUrl);
        if (obj.mode) {
          this.currentMode = obj.mode;
        }
        return buildUrlByMode(imageUrl, this.currentMode);
      },

      initQueryBotList() {
        this.refreshList();
        if (this.keyword) {
          for (let index = 0; index < this.tableData.length; index++) {
            if (
              this.tableData[index].nickname.indexOf(this.keyword) == -1 &&
              this.tableData[index].chat_content.indexOf(this.keyword) == -1
            ) {
              let temp = this.tableData[index];
              temp.show = false;
              this.$set(this.tableData, index, temp);
            } else {
              let temp = this.tableData[index];
              temp.show = true;
              this.$set(this.tableData, index, temp);
            }
          }
        }
      },
      refreshList() {
        for (let index = 0; index < this.tableData.length; index++) {
          let temp = this.tableData[index];
          temp.show = true;
          this.$set(this.tableData, index, temp);
        }
      },

      addBot() {
        if (this.total >= 500) {
          this.$vhMessage.warning('1个主题最多支持创建500个聊天机器人');
          return;
        }
        let botId = 'botId' + new Date().getTime() + Math.floor(Math.random() * 1000 + 1);
        this.tableData.push({
          id: '', // 聊天机器人id，可选
          botId: botId, //  前端交互所用id
          nickname: '机器人', // 昵称，必填
          avatar: defaultAvatar, // 头像，必填
          chat_content: '', // 发言内容，必填
          show: true
        });
        this.keyword = '';
        this.refreshList();
      },
      batchAddBot() {
        if (this.total >= 500) {
          this.$vhMessage.warning('1个主题最多支持创建500个聊天机器人');
          return;
        }
        this.importVisible = true;
      },
      open(list) {
        console.log(list);
        if (list) {
          this.tableData = JSON.parse(JSON.stringify(list));
          this.tableDataOrigin = JSON.parse(JSON.stringify(list));
        } else {
          this.tableData = JSON.parse(JSON.stringify(this.tableDataOrigin));
        }
        this.dialogBotSet = true;
      },
      initTb(list) {
        this.tableData = JSON.parse(JSON.stringify(list));
        this.tableDataOrigin = JSON.parse(JSON.stringify(list));
      },
      closeBot() {
        this.dialogBotSet = false;
      },
      saveBot() {
        for (let index = 0; index < this.tableData.length; index++) {
          if (!this.tableData[index].nickname) {
            this.$vhMessage.warning('请填写机器人昵称');
            return;
          }
          if (!this.tableData[index].chat_content) {
            this.$vhMessage.warning('请填写机器人发言内容');
            return;
          }
        }

        this.$emit('updateBots', this.tableData);

        this.dialogBotSet = false;
        this.tableData = [];
        this.keyword = '';
      },
      // 关闭导入用户名称
      cancelImport() {
        this.importVisible = false;
      },
      // 导入成功
      batchAddBotSuccess(list) {
        for (let index = 0; index < list.length; index++) {
          let botId = 'botId' + new Date().getTime() + Math.floor(Math.random() * 1000 + 1);
          this.tableData.push({
            id: '', // 聊天机器人id，可选
            botId: botId, //  前端交互所用id
            nickname: list[index].nickname, // 昵称，必填
            avatar: defaultAvatar, // 头像，必填
            chat_content: list[index].chat_content, // 发言内容，必填
            show: true
          });
        }
        this.refreshList();
        this.importVisible = false;
      },
      //批量删除
      allDelete() {
        this.bot_ids.map(e => {
          this.del({ botId: e });
        });
      }
    }
  };
</script>

<style lang="less">
  .botSetDialog {
    .tools {
      overflow: hidden;
      margin-bottom: 20px;
      .downloadBtn {
        margin-left: 12px;
        color: #3562fa;
      }
      .search {
        float: right;
        width: 180px;
        height: 36px;
      }
    }

    .flexBox {
      display: flex;
      align-items: center;
      .name {
        margin-left: 10px;
      }
      .gift-cover {
        display: inline-block;
        width: 40px;
        min-width: 40px;
        height: 40px;
        // margin: 3px 0;
        background: #ffffff;
        border-radius: 50%;
        // border-radius: 4px;
        // border: 1px solid #e6e6e6;
        // img {
        //   width: 100%;
        //   height: 100%;
        //   object-fit: scale-down;
        // }
        .vh-upload--picture-card {
          height: 100% !important;
          border: none;
          border-radius: 50%;
        }
      }
    }

    .vh-table {
      .vh-upload {
        .box .mask > span {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */

          .iconfont-v3,
          br {
            display: none;
          }
        }
      }
      .del {
        cursor: pointer;
      }

      .vh-input--limit .vh-input__inner,
      .vh-input--limit.vh-input--medium .vh-input__inner,
      .vh-input--limit.vh-input--mini .vh-input__inner,
      .vh-input--limit.vh-input--small .vh-input__inner {
        padding-right: 50px;
      }
      &::before {
        background-color: #fff;
      }
    }

    .vh-dialog__header {
      .title {
        line-height: 26px;
        font-size: 18px;
        font-weight: 500;
        color: #262626;
      }
      .total {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        color: #595959;
        margin-left: 8px;
        .highlight {
          color: #fb2626;
        }
      }
    }
  }
</style>
